<template>
  <secondary-page-layout :title="$t('pay')" :displayedNavigation="false">
    <div class="wrap-form-payment">
      <div class="form-payment-header">

      </div>
      <div class="primary-block">
        <div class="d-flex align-items-center">
          <div class="icon-box me-3">
            <icon icon-name="car-icon"></icon>
          </div>
          <span class="primary-text">{{ product.title}} <br>
            <small class="subtitle-order">Заказ: №{{invoiceId}}</small>
          </span>

        </div>
      </div>
      <div class="form-group payment-page" style="padding: 0">
        <credit-card-select @input="cardId" :is-block="true"></credit-card-select>
      </div>
      <div class="primary-block">
        <div class=" align-items-center">
          <input-field
              label="Сумма к оплате"
              name="sum"
              disabled
              v-model="fixSum"
              :is-block="true"
          />
        </div>
      </div>

    </div>
    <div class="wrap-form-payment ">
      <button @click="pay"  class="primary-btn bottom-page-btn" > {{ $t('pay') }} </button>
    </div>
  </secondary-page-layout>
</template>
<script>

import moment from "moment";
import ePayPayment from "../../mixin/ePayPayment";
import CreditCardSelect from "../../components/BuyPolicy/CreditCardSelect";
import InputField from "../../components/BuyPolicy/InputField";
import {mapGetters} from "vuex";
import {getCalculationById} from "../../api/calculations";

export default {
  mixins: [ePayPayment],
  components: {
    CreditCardSelect,
    InputField,
  },
   mounted() {
      console.log()
     this.getPaymentMethods();
      this.getCalculation()
  },
  computed: {
    ...mapGetters({
      calculatedSum: "ogpoForm/calculatedSum",
      startDate: "ogpoForm/beginDate",
      product: "INSURANCE_PRODUCT",
      phoneNumber: "ogpoForm/phoneNumber",
      selectedProductId: "ogpoForm/selectedProductId",
      products: "ogpoForm/products",
      clients: "ogpoForm/clients",
      allVerified: 'ogpoForm/allVerified',
      otpKey: 'ogpoForm/otpKey',
      friendBasket: 'ogpoForm/friendBasket',
      invoiceId: 'ogpoForm/invoiceId',
      homebankToken: 'ogpoForm/homebankToken',
      email: 'ogpoForm/email',
      selectedProductName: "ogpoForm/selectedProductName",
      period: 'ogpoForm/period',
      beginDate: "ogpoForm/beginDate",
      cascoSelected: 'ogpoForm/cascoSelected'
    }),
    calculationId() {
      return this.$route.params.id || null;
    },
    fixSum() {
      return this.calculatedSum?.fixedSum;
    },
    authUser(){
      return this.$store.getters['authUser']
    },
    issueDate(){
      if(!this.policy) {
        return;
      }

      moment.locale(this.$store.getters.selectedLanguage)

      const momentDate = moment(this.policy.issueDate)
      // console.log(momentDate)
      return momentDate.format('DD MMMM YYYY')
    },
    formInsureds() {
      if(!this.policy) {
        return;
      }
      if(this.policy.insureds && this.policy.insureds.length) {
        return this.policy.insureds.map(item => `${item.title}`).join(', ')
      }
    },
    formObjects() {
      if(!this.policy) {
        return;
      }
      if(this.policy.objects && this.policy.objects.length) {
        return this.policy.objects.join(', ')
      }
    },
    policyAmount(){
      if(!this.policy) {
        return;
      }

      return this.policy.amount;
    }
  },
  data(){
    return{
      policyID: this.$route.params.id,
      policy: null,
      cardId:'',
      form:{
        product: '',
        policyholder: '',
        insured: '',
        object: '',
      }
    }
  },
  methods: {

    async getPaymentMethods() {
      const loader = this.$loading.show()
      try{
        const {data} = await window.axios.get('/payment-methods')
        console.log(data)
        loader.hide();

      }catch (err) {
        loader.hide();

      }finally {
        loader.hide();
      }
    },
    setInfo() {
      this.info.amount = this.calculationData.amount;
      this.info.start_date = this.calculationData.beginDate;
      this.info.phone_number = this.calculationData.phoneNumber;
      this.info.fullname = this.calculationData.holder;
      this.info.insurance_type = this.calculationData.insuranceType;
    },

    async getCalculation() {
      this.calculationData = await getCalculationById(this.$route.params.id );
      this.setInfo();
    },
    async pay() {
      const loader = this.$loading.show()

      if (this.cardId) {
        try{
          const { data } = await this.payWithSavedCardById(this.cardId, this.calculationData, this.authUser);
          loader.hide();
          await this.$router.push(`/insurance-products/${this.paymentAuthData.id}/payment/success?invoiceId=${data.invoiceID}`)
        }catch (err) {
          loader.hide();
          await this.$router.push(`/insurance-products/${this.paymentAuthData.id}/payment/fail`)
        }finally {
          loader.hide();
        }
      } else {
        //await this.ogpoPayment(data, this.invoiceId, this.fixSum, this.$store.getters['authUser'])


        loader.hide();
      }
    },
  }
}
</script>

<style lang="scss">
.primary-btn{
  background: linear-gradient(270deg, #00805F 0%, #2AA65C 100%);
  /* Glow / PrimaryBtn */

  box-shadow: 0px 4px 12px rgba(42, 166, 92, 0.4);
  border-radius: 12px;
}
.form-group.payment-page p {
  padding: 10px 0;
  border-bottom: 1px solid #C8C7CC;
  margin-bottom: 0 !important;
  span.description {
    color: #99A3B3;
  }
}
.pb-80 {
  padding-bottom: 80px;
}
.title-wrap {
  padding: 0px 16px;
  align-items: center;
  margin-bottom: 20px;
  .title {
    font-weight: bold;
    font-size: 14px;
  }
}

.wrap-form-payment{
  padding: 0px 16px;
  .form-payment-header{
    padding: 0px 10px;
    .title{
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: #071222;
      margin-bottom: 10px;
    }
    .subtitle{
      font-family: 'Manrope';
      font-size: 14px;
      font-weight: 300;
      color: #99A3B3;
      margin-bottom: 16px;
    }
  }


}
.subtitle-order{
  color:#99A3B3;
}
.wrap-total__payment-treaty{
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 600;
  width: 100%;
  background-color: #fff;
  padding: 16px;
  .info-wrap, .total-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .primary-btn{
    width: 100%;
  }
  .info-wrap{
    margin-bottom: 8px;
    .info{
      font-family: 'Manrope';
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #071222;
      width: 80%;
    }
    .price{
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: #071222;
    }
  }
  .total-wrap{
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0001);
    margin-bottom: 8px;
    .info{
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: #071222;
    }
    .price{
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      text-align: right;
      color: #2AA65C;
    }
  }
}
</style>